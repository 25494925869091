.App {
  width: 100%;
  min-height: 100vh;
  background-image: url("./assets/vintage-old-rustic-cutlery-dark.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .lang {
    position: absolute;
    z-index: 1000000;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .social {
      margin-left: 8px;
      display: flex;
      align-items: center;
      gap: 16px;
      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
    .language {
      font-size: 18px;
    }
    .ReactFlagsSelect-module_selectOptionValue__vS99- {
      gap: 5px;
    }
    .ReactFlagsSelect-module_selectValue__152eS {
      gap: 5px;
      color: white;
    }
  }

  .App-header {
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
    .logo {
      width: 320px;
      object-fit: cover;
    }

    .welcome-container {
      width: 100%;
      height: 80vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("./assets/Back\ Ground\ Header.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      .hello {
        max-width: 60%;
        height: 60%;
        object-fit: cover;
      }
    }
  }

  .scroll {
    position: fixed;
    top: 5px;
    z-index: 10000;
  }
  .slider {
    width: 100%;
    z-index: 1000000;

    .wrapper {
      position: relative;
      top: 0;
      z-index: 1;
      .slider-arrow {
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(22, 22, 22, 0.5);
        font-size: 40px;
        color: white;
        font-weight: 800;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
        cursor: pointer;
        &.right {
          right: 0;
        }
        &.left {
          left: 0;
        }
      }

      .container {
        z-index: 1;
        display: flex;
        align-items: center;
        transform: translateX(0px);
        transition: all 0.3s ease;
        margin-right: 16px;

        .slider-link {
          z-index: 1;
          min-width: 130px !important;
          padding: 8px;
          text-align: center;
          color: white;
          font-size: 16px;
          font-weight: 600;
          background-color: teal;
          border-radius: 5px;
          outline: none;
          border: none;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
          cursor: pointer;
          margin-right: 16px;
        }
      }
    }
  }

  .section {
    padding-top: 30px;
    z-index: 1;
    width: 80%;
    height: 100%;
    margin: 50px auto;
    display: flex;
    justify-content: center;

    .container {
      width: 70%;
      height: 100%;
      padding: 50px;
      display: flex;
      background-color: transparent;
      border-radius: 8px;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;

      .item {
        color: white;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 24px 0px;
        &:not(:last-child, :first-child) {
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            background-color: white;
            bottom: 0px;
            width: 100%;
          }

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 2px solid #333;
            bottom: -8px;
            background-color: white;
          }
        }

        .title-img {
          width: 60%;
          height: 200px;
          object-fit: cover;
        }

        .section-img {
          width: 75%;
          height: 75%;
          object-fit: cover;
        }

        .wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            font-size: 18px;
            font-weight: 700;
            color: white;
          }
          .price {
            direction: ltr;
            color: white;
            font-size: 18px;
            font-weight: 700;
            background-color: teal;
            border-radius: 5px;
            padding: 0px 5px;
            width: 80px;
            text-align: center;
            box-shadow: 0 0 4px 2px rgba(255, 254, 254, 0.5);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .App {
    .lang {
      position: absolute;
      z-index: 1000000;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .social {
        margin-left: 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .language {
        font-size: 18px;
      }
      .ReactFlagsSelect-module_selectOptionValue__vS99- {
        gap: 5px;
      }
      .ReactFlagsSelect-module_selectBtn__19wW7 {
        padding: 0px;
      }
      .ReactFlagsSelect-module_selectValue__152eS {
        gap: 5px;
        color: white;
      }
    }

    .App-header {
      .welcome-container {
        .hello {
          object-fit: contain;
        }
      }
    }

    .slider {
      width: 100%;
      overflow-x: auto;
      .wrapper {
        position: relative;
        .slider-arrow {
          display: none;
        }
        .container {
          display: flex;
          align-items: center;
          transform: translateX(0px);
          transition: all 0.3s ease;
          margin-right: 0px;
          .slider-link {
            padding: 8px 0px;
            text-align: center;
            color: white;
            font-size: 14px;
            font-weight: 600;
            background-color: teal;
            border-radius: 5px;
            outline: none;
            border: none;
            box-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.5);
            cursor: pointer;
            margin-right: 16px;
            &:first-child {
              margin: 0px;
            }
          }
        }
      }
    }

    .section {
      width: 90%;
      height: 100%;
      margin: 50px auto;
      display: flex;
      justify-content: center;
      .container {
        width: 100%;
        height: 100%;
        padding: 16px 0px;
        display: flex;
        background-color: transparent;
        border-radius: 8px;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;

        .item {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          color: white;

          .title-img {
            height: 100px;
            width: 75%;
            object-fit: cover;
          }

          .section-img {
            width: 75%;
            height: 75%;
            object-fit: cover;
          }

          span {
            font-size: 12px;
            font-weight: 700;
          }

          .wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
            .title {
              color: white;
              font-size: 14px;
              font-weight: 700;
            }
            .price {
              direction: ltr;
              color: white;
              font-size: 14px;
              font-weight: 700;
              background-color: teal;
              border-radius: 5px;
              padding: 0px 5px;
              width: 80px;
              text-align: center;
              box-shadow: 0 0 4px 2px rgba(255, 254, 254, 0.5);
            }
          }
        }
      }
    }
  }
}
